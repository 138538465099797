import React, { Component } from "react";
import axios from "axios";
import Input from "../components/form/input/input";
import Textarea from "../components/form/textarea/textarea";
import bookingStyles from "./bookings.module.styl";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";

class Bookings extends Component {
  state = {
    name: "",
    telephone: "",
    email: "",
    message: "",
    errors: {}
  };

  handleChange = ({ target: { name, value } }) => {
    const isEmpty = value.length === 0;
    const errors = Object.assign(this.state.errors, { [name]: isEmpty });
    this.setState({ [name]: value, errors });
  };

  submit = e => {
    e.preventDefault();

    const errors = this.validate();
    const hasErrors = Object.keys(errors).some(error => errors[error]);
    if (hasErrors) return this.setState({ errors });

    const { name, telephone, email, message } = this.state;
    const data = { name, telephone, email, message };

    axios
      .post("https://formcarry.com/s/YAi4gts_xjS", data)
      .then(() => {
        this.setState({ submitted: true });
      })
      .catch(() => {
        this.setState({ error: true });
      });
  };

  validate = () => {
    const { name, email, telephone, message } = this.state;
    return {
      name: name.length === 0,
      email: email.length === 0,
      telephone: telephone.length === 0,
      message: message.length === 0
    };
  };
  render() {
    return (
      <Layout>
        <SEO title="Bookings" />
        <div>
          <h1 className="heading-medium push-bottom med-text-center">
            Bookings
          </h1>
          <p className="text-medium double-push-bottom">
            Please call <a href="tel:01474322123">01474 322123</a> or email <a href="mailto:info@chattorey.com">info@chattorey.com</a> to book a table.
          </p>
        </div>
      </Layout>
    );
  }
}

export default Bookings;
